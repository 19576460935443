import {useDeleteRequest, useGetRequest, usePostRequest, buildGetRequest} from '../base_hooks';

export function useCheckIns(key_result_id) {
  return useGetRequest(
    ['key-result', key_result_id, 'check-in'],
    buildGetRequest(['key-result', key_result_id, 'check-in'])
  );
}

export function useCreateCheckIn(
  key_result_id,
  successCallback,
  errorCallback
) {
  return usePostRequest(
    ['key-result', key_result_id, 'check-in'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['key-result', key_result_id]});
    },
    errorCallback
  );
}

export function useDeleteCheckIn(
  key_result_id,
  successCallback,
  errorCallback
) {
  return useDeleteRequest(
    ['key-result', key_result_id, 'check-in'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['key-result', key_result_id]});
    },
    errorCallback
  );
}
