import { useCheckIns, useDeleteCheckIn } from '@/hooks/key_result/checkin';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ConfidenceChip } from '../ConfidenceChip';
import { DeleteConfigButton } from '../DeleteConfigButton';
import { Loading } from '../generic/Loading';

function CheckInHistory(props) {
  const {unit, keyResult} = props;
  const {data: check_ins, isLoading} = useCheckIns(keyResult.id);
  const deleteCheckIn = useDeleteCheckIn(keyResult.id);

  if (isLoading) {
    return <Loading />;
  }

  if (check_ins.length === 0) {
    return (
      <Typography variant="title-small" textAlign="center" paddingY={2}>
        Sem check-ins
      </Typography>
    );
  }

  return (
    <Stack direction="column" px={2}>
      {check_ins.map((check_in, index) => (
        <CheckInHistoryLine
          check_in={check_in}
          index={index}
          unit={unit}
          key={check_in.id}
          deleteFunc={deleteCheckIn}
        />
      ))}
    </Stack>
  );
}

function CheckInHistoryLine(props) {
  const {check_in, index, unit, deleteFunc} = props;
  return (
    <Box>
      {index !== 0 && <Divider sx={{my: '12px'}} />}
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="label-large" color="primary">
            {`${check_in.current_value} ${unit || ''}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <ConfidenceChip
            confidence={check_in.confidence}
            sx={{justifyContent: 'left'}}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="label-medium"
            fontSize={12}
            color="neutral.800"
            component="h6"
            align="center"
          >
            {dayjs(check_in.created_at).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center'}}>
          {check_in.note && (
            <Tooltip title={check_in.note} arrow>
              <Chip
                icon={<ChatBubbleOutlineIcon sx={{ml: '7px !important'}} />}
                label="comentário"
                color="primary"
                variant="outlined"
                size="small"
              />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={1}>
          <DeleteConfigButton
            object={check_in}
            deleteMutation={deleteFunc}
            variant="text"
            sx={{py: 0}}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CheckInHistory;
