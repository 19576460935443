import { useGetArea, useGetUserDetails } from '@/hooks';
import { useGetObjective } from '@/hooks/objective';
import { Box, Divider, Stack, Typography } from '@mui/material';

function KeyResultDetails(props) {
  const {keyResult} = props;
  const {owner_id, area_id, objective_id, metric_type, description} = keyResult;

  const {data: userResult} = useGetUserDetails(owner_id ? owner_id : null);
  const {data: areaResult} = useGetArea(area_id ? parseInt(area_id) : null);
  const {data: objectiveResult} = useGetObjective(objective_id);

  return (
    <Box>
      <Typography
        variant="title-small"
        color="primary"
        sx={{textTransform: 'uppercase', mb: '15px'}}
      >
        Sobre esse Key Result
      </Typography>
      <Stack spacing={0} sx={{px: '15px'}}>
        <Stack
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="label-small" color="neutral.700">
            Responsável
          </Typography>
          <Typography variant="label-small" color="neutral.900">
            {userResult?.name || ''}
          </Typography>
        </Stack>

        <Divider sx={{my: '10px'}} />

        <Stack
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="label-small" color="neutral.700">
            Área
          </Typography>
          <Typography variant="label-small" color="neutral.900">
            {areaResult?.name || ''}
          </Typography>
        </Stack>

        <Divider sx={{my: '10px'}} />

        <Stack
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="label-small" color="neutral.700">
            Objetivo
          </Typography>
          <Typography variant="label-small" color="neutral.900">
            {objectiveResult?.name || ''}
          </Typography>
        </Stack>

        <Divider sx={{my: '10px'}} />

        <Stack
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="label-small" color="neutral.700">
            Tipo métrica
          </Typography>
          <Typography variant="label-small" color="neutral.900">
            {metric_type}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant="title-small"
        color="primary"
        sx={{textTransform: 'uppercase', mb: '15px', mt: '25px'}}
      >
        Descrição
      </Typography>
      <Typography variant="body-medium" color="neutral.700" sx={{px: '15px'}}>
        {description}
      </Typography>
    </Box>
  );
}

export default KeyResultDetails;
