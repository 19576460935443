import {Box, Chip} from '@mui/material';

const confidenceConfig = {
  on_track: {
    label: 'Vamos bater!',
    color: 'primary',
    backgroundColor: '#D3FCDE',
  },
  at_risk: {label: 'Em risco', color: 'ochre', backgroundColor: '#FFEFD2'},
  off_track: {
    label: 'Não vamos bater',
    color: 'error',
    backgroundColor: '#FCD2D2',
  },
  no_check_in: {
    label: 'Sem check-in',
    color: 'error',
    backgroundColor: '#FCD2D2',
  },
};

function ConfidenceChip(props) {
  const {confidence, sx} = props;

  const confidenceStatus = confidenceConfig[confidence || 'no_check_in'];
  const {label, color} = confidenceStatus;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Chip label={label} color={color} size="small" />
    </Box>
  );
}

export default ConfidenceChip;
