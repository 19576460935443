export const confidenceEnum = {
  on_track: {
    text: '🙂 Vamos Bater!',
    color: 'primary',
  },
  at_risk: {
    text: '😐 Em Risco',
    color: 'ochre',
  },
  off_track: {
    text: '🙁 Não vamos bater',
    color: 'error',
  },
};
