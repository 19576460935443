import {useSession} from 'next-auth/react';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

function withSessionRestriction(WrappedComponent) {
  const WithSessionRestriction = (props) => {
    const {data: session} = useSession();
    const router = useRouter();

    useEffect(() => {
      if (!session?.user?.company) {
        router.replace('/restricted-page');
      } else {
        if (router.pathname === '/restricted-page') {
          router.replace('/');
        }
      }
    }, [session, router]);

    return <WrappedComponent {...props} />;
  };

  WithSessionRestriction.displayName = `WithSessionRestriction(${getDisplayName(
    WrappedComponent
  )})`;

  return WithSessionRestriction;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withSessionRestriction;
