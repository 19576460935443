import {
  useGetArrayRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from '../base_hooks';

export function useGetAreas() {
  return useGetArrayRequest(['area']);
}

export function useGetArea(id) {
  return useGetRequest(['area', id], null, {
    enabled: id != null,
  });
}

export function useCreateArea(successCallback, errorCallback) {
  return usePostRequest(
    ['area'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['area']});
    },
    errorCallback
  );
}

export function useUpdateArea(successCallback, errorCallback) {
  return usePutRequest(
    [`area`],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['area']});
      queryClient.invalidateQueries({queryKey: ['area', newData.id]});
    },
    errorCallback
  );
}
