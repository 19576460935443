import {
  useGetArrayRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from '../base_hooks';

export function useGetPeriods() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGetArrayRequest(['period']);
}

export function useGetPeriod(id) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGetRequest(['period', id], null, {
    enabled: id != null,
  });
}

export function useCreatePeriod(successCallback, errorCallback) {
  return usePostRequest(
    ['period'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['period']});
    },
    errorCallback
  );
}

export function useUpdatePeriod(successCallback, errorCallback) {
  return usePutRequest(
    [`period`],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['period']});
      queryClient.invalidateQueries({
        queryKey: ['period', parseInt(newData.id)],
      });
    },
    errorCallback
  );
}
