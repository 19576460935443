import {handleSubmitError} from '@/components/notification/defaults';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Chip, Tooltip} from '@mui/material';
import React from 'react';
import {FormContext} from './Form';

export class CustomFormControlButton extends React.Component {
  isSubmitting() {
    return this.context.isSubmitting;
  }
  isValid() {
    return this.context.isValid;
  }
}
CustomFormControlButton.contextType = FormContext;

export class FormSubmitButton extends CustomFormControlButton {
  render() {
    if (!this.isValid()) {
      return (
        <Box sx={this.props.sx}>
          {' '}
          {/* Box para acertar a formatação e local do tooltip */}
          <Tooltip
            title="Preencha os campos corretamente"
            arrow
            placement="top"
          >
            <Box>
              {' '}
              {/* Box é necessário para Tooltip funcionar corretamente, pois Buttons Disabled não emitem eventos para o tooltip */}
              <LoadingButton
                loading={this.isSubmitting()}
                type="submit"
                disabled
                {...this.props}
                loadingPosition="end"
              >
                {this.props.children}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Box>
      );
    }
    return (
      <LoadingButton
        loading={this.isSubmitting()}
        type="submit"
        sx={this.props.sx}
        {...this.props}
        loadingPosition="end"
      >
        {this.props.children}
      </LoadingButton>
    );
  }
}

export class FormResetButton extends CustomFormControlButton {
  render() {
    return (
      <LoadingButton
        loading={this.isSubmitting()}
        type="reset"
        {...this.props}
        loadingPosition="end"
        endIcon={<ClearIcon />}
      >
        {this.props.children}
      </LoadingButton>
    );
  }
}

export class FormGenerateButton extends CustomFormControlButton {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  render() {
    if (this.state.isLoading)
      return (
        <Chip
          icon={<AutoFixHighIcon sx={{fontSize: '16px'}} />}
          label={'Carregando...'}
          color="primary"
          sx={{fontWeight: 600, cursor: ' wait', pl: '8px'}}
        />
      );
    return (
      <Chip
        icon={<AutoFixHighIcon sx={{fontSize: '16px'}} />}
        label={this.props.label ?? 'Quero sugestões do Jim'}
        color="primary"
        sx={{fontWeight: 600, cursor: 'pointer', pl: '8px'}}
        onClick={() => {
          this.setState({isLoading: true});
          this.props
            .onClick(this.context.values)
            .then((result) => {
              Object.entries(result).map(([name, content]) => {
                this.context.setFieldValue(name, content);
              });
            })
            .catch((err) => {
              handleSubmitError(err, this.context.setFieldError);
            })
            .finally(() => {
              this.setState({isLoading: false});
            });
        }}
      />
    );
  }
}
