import {
  useGetArrayRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from '../base_hooks';

export function useGetTeams() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGetArrayRequest(['team']);
}

export function useGetTeam(id) {
  return useGetRequest(['team', id], null, {
    enabled: id != null,
  });
}

export function useCreateTeam(successCallback, errorCallback) {
  return usePostRequest(
    ['team'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['team']});
    },
    errorCallback
  );
}

export function useUpdateTeam(successCallback, errorCallback) {
  return usePutRequest(
    [`team`],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['team']});
      queryClient.invalidateQueries({queryKey: ['team', newData.id]});
    },
    errorCallback
  );
}
