import { useSession } from 'next-auth/react';
import { useGetArrayRequest, useGetRequest } from '../base_hooks';


export function useCompanyInstance(company_id) {
  return useGetRequest(['company', company_id]);
}

export function useCurrentCompany() {
  const session = useSession();
  return useGetRequest(['company', session.data.company]);
}

export function useGetCompany() {
  return useGetArrayRequest(['company']);
}
