import { enqueueSnackbar } from 'notistack';
import {
  useCustomGetArrayRequest,
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from '../base_hooks';

export function useUsers(queryParams) {
  if (!queryParams) queryParams = {};
  return useCustomGetArrayRequest(['users'], queryParams);
}

export function useUser(userId) {
  return useGetRequest(['users', userId], null, {enabled: !!userId});
}

export function useCreateUser(successCallback, errorCallback) {
  return usePostRequest(['users'], successCallback, errorCallback);
}

export function useDeleteUser(successCallback, errorCallback) {
  const paramsArr = ['users'];
  return useDeleteRequest(paramsArr, 
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback();
      enqueueSnackbar('Usuário excluído com sucesso', {variant: 'success'});
      queryClient.invalidateQueries({queryKey: ['users'], exact: true});
      queryClient.removeQueries({queryKey: ['users', newData.id]});
    },
    (queryClient, oldData, newData) => {
      if (errorCallback) errorCallback();
      enqueueSnackbar('Erro ao excluir usuário', {variant: 'error'});
    }
  );
}

export function useUpdateUser(successCallback, errorCallback) {
  const paramsArr = ['users'];
  return usePutRequest(paramsArr, 
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback();
      enqueueSnackbar('Usuário editado com sucesso', {variant: 'success'});
      queryClient.invalidateQueries({queryKey: ['users', newData.id]});
    }, 
    errorCallback);
}

export function useCreateInvite(successCallback, errorCallback) {
  return usePostRequest(
    ['users', 'invites'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback();
      enqueueSnackbar('Convite enviado com sucesso', {variant: 'success'});
      queryClient.invalidateQueries({queryKey: ['users', newData.user]});
    },
    (queryClient, oldData, newData) => {
      if (errorCallback) errorCallback();
      enqueueSnackbar('Erro ao enviar convite', {variant: 'error'});
    }
  );
}
