import { Form } from '@/components/generic/forms/Form';
import { FormSubmitButton } from '@/components/generic/forms/FormButtons';
import {
  SelectMultipleInput,
  SelectSingleInput,
  SwitchInput,
  TextInput,
} from '@/components/generic/forms/FormFields';
import {
  useGetAreas,
  useGetPeriods,
  useGetUsers
} from '@/hooks';
import { Help, Lock, Public } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { useCreateObjective, useUpdateObjective } from '@/hooks/objective';
import formatOptions from '@/util/formatOptions';
import Close from '@mui/icons-material/Close';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as yup from 'yup';

function ObjectiveForm(props) {
  const {objective, onClose} = props;
  const [, setStateSelected] = useState(null);

  const {data: userResults} = useGetUsers();
  const {data: areaResults} = useGetAreas();
  const {data: periodsResults} = useGetPeriods();

  const router = useRouter();

  const objectiveCreateMutation = useCreateObjective();

  const objectiveUpdateMutation = useUpdateObjective();

  const isEditingPage = !!objective;

  return (
    <Form
      handleSubmit={(formValues) => {
        const values = {
          ...formValues,
          visibility_status: formValues.visibility_status ? 'PB' : 'PV',
        };
        isEditingPage
          ? objectiveUpdateMutation.mutateAsync({...objective, ...values})
          : objectiveCreateMutation.mutateAsync(values);
        onClose ? onClose() : router.push('/');
      }}
    >
      <Card
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          mt: '24px',
        }}
      >
        {onClose && (
          <CardHeader
            title={isEditingPage ? 'Editar Objetivo' : 'Criar novo Objetivo'}
            action={
              <IconButton aria-label="settings" onClick={onClose}>
                <Close />
              </IconButton>
            }
          />
        )}
        <CardContent>
          <Grid container sx={{width: '100%'}}>
            <Grid item xs={12}>
              <TextInput
                label="Nome do Objetivo"
                name="name"
                sx={{width: '100%'}}
                validation={yup.string().required('Nome é obrigatório')}
                initialValue={objective?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectSingleInput
                  label="Ciclo"
                  options={formatOptions(periodsResults)}
                  variant="standard"
                  name="period_id"
                  changeListener={(value) => {
                    setStateSelected(value);
                  }}
                  validation={yup.string().required('Ciclo é obrigatório')}
                  initialValue={objective?.period_id}
                />
            </Grid>
            <Grid item xs={12}>
              <SelectSingleInput
                label="Responsável"
                options={formatOptions(userResults?.results)}
                variant="standard"
                name="owner_id"
                initialValue={objective?.owner_id}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectMultipleInput
                label="Participantes"
                options={formatOptions(userResults?.results)}
                variant="standard"
                name="participants"
                changeListener={(value) => {
                    setStateSelected(value);
                  }}
                  initialValue={objective?.participants}
                />
            </Grid>
            <Grid item xs={12}>
              <SelectSingleInput
                label="Área"
                options={formatOptions(areaResults)}
                variant="standard"
                name="area_id"
                changeListener={(value) => {
                    setStateSelected(value);
                  }}
                initialValue={objective?.area_id}
              />
            </Grid>
            <Grid item xs={6}>
            <Typography sx={{mt: 0}}>
                  Visibilidade
                  <Tooltip
                    title="Um objetivo privado somente será visto pelos donos e participantes dele e de seus KeyResults. Um objetivo público será visto por todos da empresa"
                    arrow
                  >
                    <IconButton>
                      <Help />
                    </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6}>
            <SwitchInput
                  name="visibility_status"
                  initialValue={objective?.visibility_status != 'PV'}
                  icon={<Lock />}
                  checkedIcon={<Public />}
                />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Descrição"
                name="description"
                multiline
                rows={4}
              initialValue={objective?.description}
            />
            </Grid>
            <Grid item xs={3} md={4}></Grid>
            <Grid item xs={6} md={4}>
              <FormSubmitButton color="primary" variant="contained" fullWidth>
                {isEditingPage ? 'Editar objetivo' : 'Criar objetivo'}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
}

export default ObjectiveForm;