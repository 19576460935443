import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from 'react';

function DeleteConfigButton(props) {
  const {object, deleteMutation, handleCloseModal, children, variant, sx} =
    props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmit() {
    setLoading(true);
    deleteMutation.mutateAsync(object);

    setLoading(false);
    handleCloseModal && handleCloseModal();
    handleClose();
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        startIcon={<DeleteForeverRoundedIcon />}
        size="large"
        variant={variant ?? 'outlined'}
        color="error"
        sx={sx}
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Tem certeza que deseja excluir?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Essa ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={loading}
            variant="outlined"
            color="error"
          >
            Excluir
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteConfigButton;
